import { BrowserRouter, Route, Routes } from "react-router-dom";
import ErrorPage from "../ErrorPage";
import AboutUs from "../AboutUs";
import Payment from "../Payment";
import PrivacyPolicy from "../PrivacyPolicy";
import Terms from "../Terms";
import React, { CSSProperties, Suspense } from "react";
import RiseLoader from "react-spinners/RiseLoader";

const Flight = React.lazy(() => import("../Flight"));
//const Home = React.lazy(() => import("./Home"));
const FlightSearch = React.lazy(() => import("../FlightSearch"));
const FlightFinalOffer = React.lazy(() => import("../FlightFinalOffer"));
const Profile = React.lazy(() => import("../Profile"));
const Loyalty = React.lazy(() => import("../Loyalty"));
const ProfileMenu = React.lazy(() => import("../ProfileMenu"));
const Layout = React.lazy(() => import("../Layout"));

const override: CSSProperties = {
  display: "block",
  margin: "0 auto",
  borderColor: "#000046",
};

export const MainROute = ({
  isOpen,
  setIsOpen,
}: {
  isOpen: boolean;
  setIsOpen: any;
}) => {
  return (
    <BrowserRouter>
      <Suspense
        fallback={
          <div
            className="w-full items-center h-screen flex justify-center"
            key={0}
          >
            <RiseLoader
              color={"#000046"}
              loading={true}
              cssOverride={override}
              size={10}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        }
      >
        <Layout isOpen={isOpen} setIsOpen={setIsOpen}>
          <Routes>
            <Route path="/" element={<Flight />} errorElement={<ErrorPage />} />
            <Route
              path="/flights"
              element={<Flight />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/final"
              element={<FlightFinalOffer />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/flights/results/:search"
              element={<FlightSearch />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/payment/:feature/:ref"
              element={<Payment />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/profile"
              element={<Profile />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/policy"
              element={<PrivacyPolicy />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/about"
              element={<AboutUs />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/terms"
              element={<Terms />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/my_loyalty"
              element={<Loyalty />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/profile_menu"
              element={<ProfileMenu />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/error"
              element={<ErrorPage />}
              errorElement={<ErrorPage />}
            />
          </Routes>
        </Layout>
      </Suspense>
    </BrowserRouter>
  );
};
