import moment from "moment";
import { toast } from "react-toastify";
import { addDays } from "date-fns";

export const dateFormatter = new Intl.DateTimeFormat("en-US", {
  weekday: "short",
  month: "short",
  day: "2-digit",
});

function getLocaleForCurrency(currency: string): string {
  // Example mapping, this should be expanded based on actual use cases
  const currencyLocaleMap: { [key: string]: string } = {
    USD: "en-US",
    EUR: "de-DE",
    JPY: "ja-JP",
    NGN: "en-NG",
    // add more currencies and their likely locales
  };

  return currencyLocaleMap[currency] || "en-US"; // default to "en-US" if not found
}
export function formatCurrency(amount: any, currency = "USD") {
  const locale = getLocaleForCurrency(currency);
  try {
    const formattedAmount = new Intl.NumberFormat(locale, {
      style: "currency",
      currency: currency,
    }).format(amount);
    return formattedAmount;
  } catch (error) {
    console.error("Error formatting currency:", error);
    return amount; // Return the original amount in case of an error
  }
}

export const formatDate = (dateObj: any) => {
  if (dateObj) {
    // Create a new Date object using the provided year, month, and day
    const date = new Date(dateObj.year, dateObj.month - 1, dateObj.day);

    // Get the day of the week (e.g., "Sun", "Mon", etc.)
    const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

    // Get the month name (e.g., "Jan", "Feb", etc.)
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const month = monthNames[dateObj.month - 1];

    return `${daysOfWeek[date.getDay()]}, ${dateObj.day} ${month}`;
  } else {
    return ""; // Return an empty string if dateObj is falsy
  }
};

export function subtractDates(date1: any, date2: any) {
  const date1Object: any = new Date(date1);
  const date2Object: any = new Date(date2);

  // Calculate the time difference in milliseconds
  const timeDifference = date1Object - date2Object;

  // Convert the time difference to hours and minutes
  const hours = Math.floor(timeDifference / (60 * 60 * 1000));
  const minutes = Math.floor((timeDifference % (60 * 60 * 1000)) / (60 * 1000));

  // Format the result as "hh:mm"
  const formattedResult = `${hours.toString().padStart(2, "0")}h ${minutes
    .toString()
    .padStart(2, "0")}m`;

  return formattedResult;
}
export const getDefaultDateRange = () => {
  // Get the current date
  const currentDate = new Date();

  // Calculate the date for "to" as the current date + 5 days
  const to = addDays(new Date(), 5);

  // Format the "from" and "to" dates into the desired format
  const from = {
    day: currentDate.getDate(),
    month: currentDate.getMonth() + 1, // Month is zero-based, so add 1
    year: currentDate.getFullYear(),
  };

  const toFormatted = {
    day: to.getDate(),
    month: to.getMonth() + 1, // Month is zero-based, so add 1
    year: to.getFullYear(),
  };

  return { from, to: toFormatted };
};

export const sortSegment = (data: any) => {
  let sortedSegment: any = [];

  for (let i = 0; i < data.length; i++) {
    const item = data[i];
    if (i > 0) {
      sortedSegment = [
        ...sortedSegment,
        {
          type: "transfer",
          time: subtractDates(
            data[i].departuredatetime,
            data[i - 1].arrivalDateTime
          ),
          arrival: item.departurecode,
        },
      ];
    }
    sortedSegment = [
      ...sortedSegment,
      {
        type: "trip",

        arrival: item.departurecode,
        departure: item.arrivalCode,
      },
    ];
  }

  return sortedSegment;
};

export const getDateRange = () => {
  var today = new Date(); // Get today's date

  // Yesterday's date
  var yesterday = new Date();
  yesterday.setDate(today.getDate() - 1);

  // Date range of the current week (Sunday to Saturday)
  var currentWeekStart = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() - today.getDay()
  );
  var currentWeekEnd = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() + (6 - today.getDay())
  );

  // Date range of the last week
  var lastWeekStart = new Date(currentWeekStart);
  lastWeekStart.setDate(currentWeekStart.getDate() - 7);
  var lastWeekEnd = new Date(currentWeekEnd);
  lastWeekEnd.setDate(currentWeekEnd.getDate() - 7);

  // Date range of the current month
  var currentMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);
  var currentMonthEnd = new Date(today.getFullYear(), today.getMonth() + 1, 0);

  // Date range of the last month
  var lastMonthStart = new Date(today.getFullYear(), today.getMonth() - 1, 1);
  var lastMonthEnd = new Date(today.getFullYear(), today.getMonth(), 0);

  // Format dates to YYYY-MM-DD
  var formatDate = function (date: any) {
    var year = date.getFullYear();
    var month = String(date.getMonth() + 1).padStart(2, "0");
    var day = String(date.getDate()).padStart(2, "0");
    return year + "-" + month + "-" + day;
  };

  // Return the date ranges as an object with formatted dates
  return {
    today: formatDate(today),
    yesterday: formatDate(yesterday),
    currentWeekStart: formatDate(currentWeekStart),
    currentWeekEnd: formatDate(currentWeekEnd),
    lastWeekStart: formatDate(lastWeekStart),
    lastWeekEnd: formatDate(lastWeekEnd),
    currentMonthStart: formatDate(currentMonthStart),
    currentMonthEnd: formatDate(currentMonthEnd),
    lastMonthStart: formatDate(lastMonthStart),
    lastMonthEnd: formatDate(lastMonthEnd),
  };
};

export const getStops = (trip: any, airport: any) => {
  let stopString = `${trip.segments.length - 1} stops in `;
  const segments = trip.segments.slice(0, trip.segments.length - 1);
  const lastIndex = segments.length - 1;

  for (let i = 0; i < lastIndex; i++) {
    const item = segments[i];
    stopString = stopString
      .concat(
        airport[item.arrivalCode]
          ? airport[item.arrivalCode]?.region
          : item?.arrivalCode
      )
      .concat(", ");
  }

  if (lastIndex >= 0) {
    const lastItem = trip.segments[lastIndex];
    stopString = stopString.concat(
      airport[lastItem.arrivalCode]
        ? airport[lastItem.arrivalCode]
          ? airport[lastItem.arrivalCode]?.region
          : lastItem?.arrivalCode
        : lastItem?.arrivalCode
    );
  }
  return stopString;
};
export const getStops2 = (trip: any, airport: any) => {
  let count = 0;
  for (const item of trip) {
    if (item.type === "transfer") {
      count++;
    }
  }
  let stopString = ``;

  const lastIndex = trip.length - 1;

  for (let i = 0; i < lastIndex; i++) {
    const item = trip[i];
    if (item.type === "transfer") {
      stopString = stopString
        .concat(
          airport[item.arrival] ? airport[item.arrival]?.region : item.arrival
        )
        .concat(", ");
    }
  }

  return stopString;
};
export function capitalizeFirstLetterOfWords(text: any) {
  return text
    ? text
        .toLowerCase()
        .replace(/(^|\s)\S/g, (match: any) => match.toUpperCase())
    : "N/A";
}
export const compareFlights = (flight1: any, flight2: any) => {
  // Compare relevant details, adjust as needed
  const details1 = flight1.itineraries[0].segments.map(
    (segment: any) =>
      `${segment.departurecode}-${segment.arrivalCode}-${segment.flightNumber}`
  );
  const details2 = flight2.itineraries[0].segments.map(
    (segment: any) =>
      `${segment.departurecode}-${segment.arrivalCode}-${segment.flightNumber}`
  );

  // Join the details arrays to create a unique identifier for each flight
  const identifier1 = details1.join(",");
  const identifier2 = details2.join(",");

  // Compare the identifiers
  if (identifier1 < identifier2) {
    return -1;
  } else if (identifier1 > identifier2) {
    return 1;
  } else {
    return 0;
  }
};

export const sortFlightOffer2 = (data: any, offers: any) => {
  let sorted: any = {};

  for (const item of data) {
    let itemKey = getFlightKey(offers[item]);
    if (sorted[itemKey]) {
      sorted[itemKey] = {
        ...sorted[itemKey],
        ECONOMY: item,
      };
    } else {
      sorted[itemKey] = {
        ...sorted[itemKey],
        ECONOMY: item,
      };
    }
  }

  return sorted;
};
export const sortFlightUpsellOffer = (data: any) => {
  let sorted: any = {};
  for (const item of data) {
    let itemKey = item.travelerPricings[0].fareDetailsBySegment[0].cabin;
    if (sorted[itemKey]) {
      sorted = {
        ...sorted,
        [itemKey]: [...sorted[itemKey], item],
      };
    } else {
      sorted = {
        ECONOMY: null,
        PREMIUM_ECONOMY: null,
        BUSINESS: null,
        FIRST: null,
        ...sorted,
        [itemKey]: [item],
      };
    }
  }

  return sorted;
};

let getFlightKey = (item: any) => {
  let itemKey = "";
  for (const it of item.itineraries) {
    for (const seg of it.segments) {
      itemKey = itemKey + seg.carrierCode + seg.number + "-";
    }
    itemKey =
      itemKey +
      it.segments[0].departurecode +
      "-" +
      it.segments[it.segments.length - 1].arrivalCode;
    itemKey =
      itemKey +
      it.segments[0].departuredatetime +
      "-" +
      it.segments[it.segments.length - 1].arrivalDateTime;
  }
  return itemKey;
};

export const sortAmenities = (amenities: any) => {
  let sorted: any = {};
  for (const item of amenities) {
    if (sorted[item.code]) {
      sorted = {
        ...sorted,
        [item.code]: [...sorted[item.code], item],
      };
    } else {
      sorted = {
        ...sorted,
        [item.code]: [item],
      };
    }
  }
  return sorted;
};

export const slugToText = (slug: any) => {
  return slug.replace(/-/g, " ").replace(/(?:^|\s)\S/g, function (a: any) {
    return a.toUpperCase();
  });
};

export const formatDayWithZero = (day: any) => {
  let x = day + "";

  if (x.length === 1) {
    return `0${x}`;
  } else {
    return x;
  }
};
export const formatText = (text: any) => {
  // Split the text into sentences based on the period (.)
  const sentences = text.split(". ");

  // Add line breaks after each sentence
  const formattedText = sentences.join(".\n");

  return formattedText;
};
export const successMessage = (msg: any) => {
  toast.success(msg, {
    position: "bottom-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

export const errorMessage = (msg: any) => {
  toast.warning(msg, {
    position: "bottom-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

export const isDateLessThan = (firstDate: any, secondDate: any) => {
  console.log(firstDate, secondDate);
  return firstDate < secondDate;
};

export const addUpDurations = (data: any) => {
  let sumUp = 0;

  for (const item of data) {
    sumUp = sumUp + item.duration;
  }
  return convertMinutesToHoursMinutes(sumUp);
};
export function convertMinutesToHoursMinutes(minutes: any) {
  const hours = Math.floor(minutes / 60);
  const mins = minutes % 60;
  return `${hours}h ${mins}m`;
}

export const sortAddons = (addOns: any) => {
  let sorted: any = {};
  for (const item of addOns) {
    if (sorted[item.type]) {
      sorted[item.type] = [...sorted[item.type], item];
    } else {
      sorted[item.type] = [item];
    }
  }
  return sorted;
};

export const formatPassForm = (
  passForm: any,
  contact: any,
  travAddons: any
) => {
  const travelers = Object.keys(passForm).map((key) => {
    const type = key.split("_")[0];
    const originalPassport = passForm[key];

    let passport = Object.entries(originalPassport).reduce(
      (acc, [field, value]: any) => {
        if (value !== "") {
          if (field === "issuedby" || field === "nationality") {
            acc[field] = value.code;
          } else if (field === "title") {
            acc[field] = value.value;
          } else if (field === "dateofbirth" || field === "expiredon") {
            acc[field] = moment(value).format("YYYY-MM-DD");
          } else {
            acc[field] = value;
          }
        }
        return acc;
      },
      {} as any
    );
    passport = {
      ...passport,
      email: contact.email,
      phoneNumber: contact.phoneNumber,
    };
    return {
      type,
      passport,
      addOns: travAddons[key],
    };
  });

  return travelers;
};

export const sortHistory = (data: any) => {
  let sorted: any = {};
  for (const item of data) {
    if (sorted[item.featureKey]) {
      sorted[item.featureKey] = [...sorted[item.featureKey], item];
    } else {
      sorted[item.featureKey] = [item];
    }
  }
  return sorted;
};

export function validatePayloadWithDetails(
  payload: any,
  allowCertainFieldsNull: any
) {
  const conditionalFields = [
    "passportid",
    "nationality",
    "issuedby",
    "expiredon",
  ];
  let missingFields = [];
  let x = " Field ";

  for (const key in payload) {
    if (typeof payload[key] === "object" && payload[key] !== null) {
      for (const prop in payload[key]) {
        if (conditionalFields.includes(prop) && !allowCertainFieldsNull) {
          continue;
        }
        if (payload[key][prop] === "" || payload[key][prop] === null) {
          missingFields.push(`${key}.${prop}`);
          x = x.concat(key.concat(" ").concat(prop).concat(", "));
        }
      }
    }
  }
  x = x + " are missing";

  if (missingFields.length > 0) {
    return {
      isValid: false,
      missingFields,
      x: formatMissingFields(missingFields),
    };
  }

  return { isValid: true };
}
function formatMissingFields(fields: any) {
  return fields
    .map((field: any) => {
      // Extract parts
      let parts = field.split(".");
      let adultIndex = parts[0].match(/\d+/)[0];
      let fieldName = parts[1].replace(/_/g, " ");

      // Determine the ordinal for adult
      let ordinal;
      switch (parseInt(adultIndex)) {
        case 0:
          ordinal = "first";
          break;
        case 1:
          ordinal = "second";
          break;
        case 2:
          ordinal = "third";
          break;
        default:
          ordinal = parseInt(adultIndex) + 1 + "th";
      }

      // Return the formatted string
      return `The ${ordinal} adult's ${fieldName} is missing.`;
    })
    .join(" ");
}
