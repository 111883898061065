import { useState } from "react";
import { Dispatch, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import * as Actions from "./store/actions";
import { jwtDecode } from "jwt-decode";
import { /*  GoogleLogin, */ useGoogleOneTapLogin } from "@react-oauth/google";

import { MainROute } from "./routes";
//import { googleLogout } from "@react-oauth/google";

function App() {
  const dispatch: Dispatch<any> = useDispatch();
  const isAuth = useSelector((state: any) => state.client.isAuth);
  const user = useSelector((state: any) => state.client.user);
  const seconds = useSelector((state: any) => state.misc.seconds);
  const isActive = useSelector((state: any) => state.misc.isActive);
  const info = useSelector((state: any) => state.app.info);
  const currency = useSelector((state: any) => state.app.currency);
  const [isOpen, setIsOpen] = useState(false);

  /*   const [seconds, setSeconds] = useState(initialSeconds);
  const [isActive, setIsActive] = useState(false); */

  useEffect(() => {
    let interval: any = null;
    if (isActive && seconds > 0) {
      interval = setInterval(() => {
        dispatch(Actions.setSeconds());
      }, 1000);
    } else if (seconds === 0) {
      clearInterval(interval);
      window.location.replace("/flights");
    }
    return () => clearInterval(interval);
  }, [isActive, seconds]);
  useEffect(() => {
    if (localStorage.getItem("svToken") !== null) {
      dispatch(Actions.loadClient());
    }
    //dispatch(Actions.getUserPastSearches());
    dispatch(Actions.loadCountries());
    dispatch(Actions.loadFlightDeals());
    // dispatch(Actions.loadCity());
    dispatch(Actions.loadProvider());
    dispatch(Actions.loadAirport());
    dispatch(Actions.loadVendor());
    dispatch(Actions.loadDestinations());
    dispatch(Actions.getClientInfo());
  }, []);
  useEffect(() => {
    if (isAuth) {
      dispatch(Actions.loadHistory());
      if (user.email) {
        dispatch(Actions.loadPriceAlert());
      }

      dispatch(Actions.getFrequentTraveler(user.email));
    }
  }, [isAuth]);

  useEffect(() => {
    if (user) {
      if (!user.fullname || !user.phone) {
        console.log(user);
        setIsOpen(true);
      }
    }
  }, [user]);

  useEffect(() => {
    if (info && info.country_name && info.country_name !== "-") {
      dispatch(
        Actions.ofcQuery({
          queryType: "locale",
          departCountry: info.country_name,
          // departCountry: "Nigeria",
          //currency: "NGN",
          currency: currency?.code,
        })
      );
    }
  }, [info, currency]);

  const handleLoginSuccess = (tokenResponse: any) => {
    console.log("Token Response:", tokenResponse);
    const userInfo = tokenResponse.access_token
      ? {
          ...tokenResponse,
          provider: "google",
        }
      : {
          credential: tokenResponse.credential,
          provider: "google",
          ...jwtDecode(tokenResponse.credential), // Decoding JWT to get user data
        };

    dispatch(
      Actions.joinSocialClient(userInfo, successCallback, errorCallback)
    );
  };
  const successCallback = (data: any) => {
    if (!data?.client.email || !data?.client.fullname) {
    } else {
      //  setIsOpen(false);
    }
  };

  const errorCallback = () => {
    // Handle error
  };

  useGoogleOneTapLogin({
    disabled: localStorage.getItem("svToken") !== null,
    onSuccess: handleLoginSuccess,
    onError: () => console.log("One Tap Login Failed"),
    auto_select: true,
    use_fedcm_for_prompt: true,
    prompt_parent_id: "oneTapContainer", // ID of the element where you want the prompt to appear
    cancel_on_tap_outside: false,
    // hosted_domain:
    //prompt_mode: 'display',
  });

  /*   useEffect(() => {
    dispatch(
      Actions.setFlightDate({
        startDate: addDays(new Date(), 7),
        endDate: addDays(new Date(), 14),
        key: "selection",
      })
    );
  }, []); */
  //googleLogout();

  return (
    /*   <ErrorBoundary>
      <InactivityHandler />
     */
    <>
      <MainROute isOpen={isOpen} setIsOpen={setIsOpen} />
    </>

    /* <RouterProvider router={router} /> */
    /*     </ErrorBoundary> */
  );
}

export default App;
