/* @ts-ignore */
import UserAvatar from "react-user-avatar";
import * as Actions from "../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { Popover } from "@headlessui/react";
import { useNavigate } from "react-router-dom";
import { useModal } from "../Sliders/Modal/ModalContext";
import { Dispatch } from "react";
import { formatCurrency } from "../util/Helpers";
import { MdConnectWithoutContact } from "react-icons/md";

const Avatar = ({ color = "border-slate-100", setIsOpen2 }: any) => {
  const navigate = useNavigate();
  const dispatch: Dispatch<any> = useDispatch();
  const { showModal } = useModal();
  const isAuth = useSelector((state: any) => state.client.isAuth);
  const user = useSelector((state: any) => state.client.user);
  const currency = useSelector((state: any) => state.app.currency);
  const accountBalance = useSelector(
    (state: any) => state.client.accountBalance
  );
  return (
    <div className="z-[3]">
      {isAuth ? (
        <Popover>
          {({ close }) => (
            <>
              <Popover.Button>
                <div
                  className={`flex hover:bg-[#ffffff40] ${color} border-[1px] rounded cursor-pointer`}
                >
                  <div className="p-2 md:text-white  flex justify-center items-center ">
                    {user?.email}
                  </div>
                </div>
              </Popover.Button>

              <Popover.Panel
                className={
                  "flex z-[2] flex-col absolute right-0 mt-2 shadow-lg  bg-white py-3  rounded-lg w-[350px]"
                }
              >
                <div className="flex items-center justify-between text-[#000046] py-3 px-5">
                  <div className="space-y-1">
                    {user.fullname && (
                      <div className="font-semibold text-sm">
                        Hi, {user.fullname}
                      </div>
                    )}

                    <div className="font-light text-xs">{user.email}</div>
                    <div
                      className="font-semibold cursor-pointer text-xs"
                      onClick={() => {
                        setIsOpen2(true);
                        close();
                      }}
                    >
                      {" "}
                      {currency ? currency.code : "N/A"}
                    </div>
                  </div>
                  {/* <div className="bg-zinc-500 px-3 py-1 h-8 flex items-center justify-center text-white font-bold rounded-lg">
                    Silver
                  </div> */}
                </div>
                {accountBalance && (
                  <div className="flex items-center justify-between text-[#000046] py-3 px-5">
                    <div className="space-y-1">
                      <div className="font-light text-sm">Reward Earned</div>
                      <div className="font-semibold text-sm flex space-x-1">
                        <img width={20} src="/coins.png" />
                        <div>{formatCurrency(accountBalance, "USD")}</div>
                      </div>
                    </div>
                  </div>
                )}
                {user?.refCode && (
                  <div className="flex items-center justify-between text-[#000046] py-3 px-5">
                    <div className="space-y-1 ">
                      <div className="font-light text-sm">Referral url</div>
                      <div className="font-semibold text-sm flex space-x-1 items-center">
                        <MdConnectWithoutContact className="text-4xl" />
                        <div className="truncate">
                          <a
                            href={`https://stavigo.com?refCode=${user.refCode}`}
                          >
                            {`https://stavigo.com?refCode=${user.refCode}`.substring(
                              0,
                              33
                            ) + "..."}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <hr className=" border-[1px]" />
                <div className="space-y-5 text-black font-medium text-base px-5">
                  <div
                    onClick={() => {
                      navigate("/profile#Profile");
                    }}
                    className="flex cursor-pointer items-center text-base text-[#000046] font-medium "
                  >
                    Account
                  </div>
                  <div
                    onClick={() => {
                      navigate("/profile#My%20Bookings");
                    }}
                    className="flex cursor-pointer items-center text-base text-[#000046] font-medium "
                  >
                    My bookings
                  </div>
                  {/*  <div
                    onClick={() => {
                      navigate("/profile#Coupons");
                    }}
                    className="flex cursor-pointer items-center text-base text-[#000046] font-medium "
                  >
                    Coupons
                  </div> */}
                </div>
                <hr />
                <div
                  onClick={() => {
                    dispatch(Actions.logoutClient());
                  }}
                  className="flex cursor-pointer px-5 items-center text-base text-[#000046] font-medium space-x-2"
                >
                  Sign Out
                </div>
              </Popover.Panel>
            </>
          )}
        </Popover>
      ) : (
        <div className="my-5">
          <button
            type="button"
            onClick={() => {
              showModal("authModal");
            }}
            className=" w-full bg-[#EE723A] justify-center text-base items-center gap-2 flex text-[#fff] font-bold px-4 py-1 rounded hover:bg-[#1c1a3a] focus:outline-none focus:bg-[#1c1a3a]"
          >
            Sign In
          </button>
        </div>
      )}
    </div>
  );
};

export default Avatar;
